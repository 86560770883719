<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card border border-radius-12">
          <PageHeader :title="title" class="poppins fw-normal ps-3 pt-3" />

          <div class="card-body table">
            <div class="main_header_ctas d-flex gap-2 flex-wrap align-items-center justify-content-between py-2">
              <div class="add_new border-orange rounded-pill p1">
                <router-link
                  to="/coupon/add"
                  class="btn btn-primary border-0 rounded-pill px-5 fw-bold text-white btn btn-primary waves-effect waves-light w-md primary-button"
                >
                  Add New Coupon
                </router-link>
              </div>
            </div>
            <div class="table-responsive">
              <table id="datatable" class="table table-centered table-nowrap mb-0">
                <thead class="table-light bg-transparent">
                  <tr>
                    <th>Coupon ID</th>
                    <!-- <th>Coupon Name</th> -->
                    <th>Percent Off</th>
                    <th>Expiration Date</th> <!-- New Column -->
                    <th>Description</th> <!-- New Column -->
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="loading" class="text-center">
                    <td colspan="7">
                      <APILoader :loading="loading" class="align-middle text-center"></APILoader>
                    </td>
                  </tr>
                  <tr v-else v-for="(coupon) in coupons" :key="coupon.id">
                    <td>{{ coupon.id }}</td>
                    <!-- <td>{{ coupon.name || 'N/A' }}</td> -->
                    <td>{{ coupon.percent_off }}%</td>
                    <td>{{ formatDate(coupon.expiration_date) || 'N/A' }}</td> <!-- Display Expiration Date -->
                    <td>{{ coupon.description || 'N/A' }}</td> <!-- Display Description -->
                    <td>{{ formatDate(coupon.created) }}</td>
                    <td class="tabel-icon">
                      <div class="d-flex">
                        <button
                          @click="deleteLocationType(coupon.id)"
                          class="p-0 bg-transparent border-0"
                        >
                          <span class="text-danger">
                            <i class="uil-trash-alt"></i>
                          </span>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- end table-responsive -->
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import Swal from "sweetalert2";
import $ from "jquery";

export default {
  name: "coupon-listing",
  components: {
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "Coupons Listing",
      loading: true,
      coupons: [],  // Updated variable name to reflect coupon data
    };
  },
  mounted() {
    this.allCoupons();
  },
  methods: {
    renderData(response) {
      this.coupons = response.data.data; // Update variable name to 'coupons'
      this.loading = false;
      if ($.fn.dataTable.isDataTable("#datatable")) {
        let table = $("#datatable").DataTable();
        table.destroy();
      }
      setTimeout(() => {
        $("#datatable").DataTable({
          ordering: false,
          lengthMenu: [
            [5, 10, 25, 50, -1],
            [5, 10, 25, 50, "All"],
          ],
          pageLength: 25,
        });
      });
    },
    allCoupons() {
      this.$axios
        .get("coupon/list")
        .then((response) => {
          setTimeout(() => this.renderData(response), 1500);
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    async delete(id) {
      try {
        const {
          data: { status },
        } = await this.$axios.get("coupon/delete/" + id);
        this.allCoupons();
        this.responseDelete = status;
      } catch (error) {
        console.log(error);
      }
    },
    deleteLocationType(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.delete(id);
          let status = this.responseDelete;
          if (status === true) {
            Swal.fire("Deleted!", "Record has been deleted.", "success");
            this.allCoupons();
          }
        }
      });
    },
    formatDate(timestamp) {
    // Convert timestamp from seconds to milliseconds
    const date = new Date(timestamp * 1000); 
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid date'; // Handle invalid date
    }
    return date.toLocaleDateString(); // Format as needed
  },
  },
};
</script>
